table {
    width: 100%;
}

table td {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* padding: 0.5rem; */
    line-height: 2rem;
}

table th {
    text-align: start;
}